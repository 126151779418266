<template>
  <el-row :gutter="10">
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
      <div class="grid-content bg-purple-light">
        <div class="system-user-search">
          <div style="padding-bottom:10px;">
            <div>
              <el-button size="small" type="primary" @click="onOpenAddMenu">
                {{ $t('添加') }}
              </el-button>
              <el-button size="small" type="info" @click="deleteBlackItemss">
                {{ $t('删除') }}
              </el-button>
            </div>
          </div>
          <div>
            <el-input size="small" v-model="input" :placeholder="$t('IP地址或号码')" prefix-icon="el-icon-search"
                      style="max-width: 180px;margin-right:3px;" @keydown.enter="handleSearch"></el-input>
            <el-button size="small" type="primary" @click="handleSearch">{{ $t('查询') }}</el-button>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" class="table"
                  @selection-change="selectionChange" id="out-table" @sort-change="changeTableSort">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column :label="$t('类型')" width="120" show-overflow-tooltip>
            <template #default="scope">
              <el-tag size="medium" effect="plain" type="primary" v-if="scope.row.type == 0">{{ $t('IP地址') }}</el-tag>
              <el-tag size="medium" effect="plain" type="success" v-if="scope.row.type == 1">{{ $t('号码') }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="data" :label="$t('信息')" show-overflow-tooltip width="140"></el-table-column>
          <el-table-column prop="comment" :label="$t('描述')" show-overflow-tooltip min-width="180"></el-table-column>
          <el-table-column prop="create_time" :label="$t('创建时间')" show-overflow-tooltip
                           sortable="custom" width="180"></el-table-column>
          <el-table-column prop="path" :label="$t('操作')" width="100" fixed="right">
            <template #default="scope">
              <el-button size="mini" type="text" @click="onOpenEditMenu(scope.row)">{{ $t('编辑') }}</el-button>
              <el-button size="mini" type="text" @click="onRowDel(scope.row)"
                         class="u-delete" style="margin-left: 0px !important;">{{ $t('删除') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination v-if="clientWidth >= 1000" @size-change="onHandleSizeChange"
                       @current-change="onHandleCurrentChange" class="mt15" :pager-count="5" :page-count="pageCount"
                       :current-page="pageNum" background :page-size="pageSize"
                       layout="total, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <el-pagination v-else @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                       :page-size="pageSize" small layout=" prev, pager, next" :page-count="pageCount"
                       :current-page="pageNum"
                       :total="total" class="mt15"></el-pagination>
      </div>
      <AddMenu ref="addMenuRef" @print="print"/>
      <EditMenu ref="editMenuRef" @print="print"/>
    </el-col>
  </el-row>
</template>
<script>
import {
  reactive,
  toRefs,
  ref,
  onMounted
} from 'vue';
import {useI18n} from "vue-i18n";
import AddMenu from './components/addMenu.vue';
import EditMenu from './components/editMenu.vue';
// import axios from 'axios';
import {
  ElMessageBox,
  ElNotification
} from 'element-plus';
import {
  getBlackList,
  deleteBlackItem,
  deleteBlackItems,
} from "@/http/safety.api.js"

export default {
  name: 'ipBlacklist',
  components: {
    AddMenu,
    EditMenu,
  },
  setup(props, context) {
    const {t}       = useI18n()
    let state       = reactive({
      sysTitle: '',
      tableData: [],
      input: '',
      pageSize: 5,//每页数据条数
      pageNum: 1,//页码
      total: 0,//总数据条数
      pageCount: 0,//总页数
      selectionList: [],
      clientWidth: '',
      sortType: '',
      sortBy: ''
    });
    const blackList = (state) => {
      let params = {
        page: state.pageNum
      }
      getBlackList(params).then(res => {
        state.tableData = res.data.list;
        state.pageCount = res.data.pages;
        state.pageSize  = res.data.length;
        state.total     = res.data.rows;
        context.emit('changeTag', res.tag);
      }).catch(err => {
        console.log(err, 'err');
      })
    }
    const print     = () => {
      blackList(state);
    }
    onMounted(() => {
      //axios !== service
      blackList(state);
      state.clientWidth = window.innerWidth;
    })
    const addMenuRef            = ref();
    const editMenuRef           = ref();
    // 打开新增菜单弹窗
    const onOpenAddMenu         = () => {
      addMenuRef.value.openDialog();
    };
    // 打开编辑菜单弹窗
    const onOpenEditMenu        = (row) => {
      editMenuRef.value.openDialog(row);
    };
    //搜索查询ip
    const handleSearch          = () => {
      let params = {
        length: state.pageSize,
        page: state.pageNum,
        keyword: state.input,
        sort_type: state.sortType,
        sort_by: state.sortBy
      }
      getBlackList(params).then(res => {
        state.tableData = res.data.list;
        state.pageCount = res.data.pages;
        state.total     = res.data.rows;
      })
    }
    // 当前行删除
    const onRowDel              = (row) => {
      ElMessageBox.confirm(t("你确定要删除该数据吗"), t("提示"), {
        confirmButtonText: t("确定"),
        cancelButtonText: t("取消"),
        type: "warning",
        callback: (action) => {
          if (action === "confirm") {
            let params = {
              id: row.id
            }
            deleteBlackItem(params).then((res) => {
              if (res.code == 200) {
                ElNotification({
                  title: t("成功"),
                  message: t("删除成功"),
                  type: 'success',
                  duration: 2000,
                });
                blackList(state)
              }
            })

          }
        },
      });
    };
    //批量删除
    const deleteBlackItemss     = () => {
      if (state.selectionList.length === 0) {
        ElNotification({
          title: t("提示"),
          message: t("请选择要删除的黑名单"),
          type: "warning",
          duration: 2000,
        });
        return;
      } else {
        ElMessageBox.confirm(t("你确定要删除该数据吗"), t("提示"), {
          confirmButtonText: t("确定"),
          cancelButtonText: t("取消"),
          type: "warning",
          callback: (action) => {
            if (action === "confirm") {
              let params = {
                ids: state.selectionList
              }
              deleteBlackItems(params).then(res => {
                if (res.code == 200) {
                  ElNotification({
                    title: t("成功"),
                    message: t("删除成功"),
                    type: "success",
                    duration: 2000,
                  });
                  blackList(state)
                } else {
                  ElNotification({
                    title: t('失败'),
                    message: res.msg,
                    type: 'error',
                    duration: 2000,
                  });
                }
              }).catch(err => {
                console.log(err, "err")
              })
            }
          }
        })
      }
    }
    const selectionChange       = (list) => {
      let array           = list.map(item => {
        return item.id
      })
      state.selectionList = array;
    }
    const changeTableSort       = (column) => {
      state.sortBy   = column.prop;
      state.sortType = column.order;
      handleSearch()
    }
    // 分页改变
    const onHandleSizeChange    = (val) => {
      state.pageSize = val;
      if (state.input !== '') {
        handleSearch();
      } else {
        blackList(state)
      }
    };
    // 分页改变
    const onHandleCurrentChange = (val) => {
      state.pageNum = val;
      if (state.input !== '') {
        handleSearch();
      } else {
        blackList(state)
      }
    };
    return {
      ...toRefs(state),
      onRowDel,
      onOpenAddMenu,
      onOpenEditMenu,
      addMenuRef,
      editMenuRef,
      changeTableSort,
      onHandleSizeChange,
      onHandleCurrentChange,
      deleteBlackItemss,
      selectionChange,
      handleSearch,
      print
    };
  }
}
</script>
<style lang="scss" scoped>
.table {
  :deep(.hidden-columns) {
    visibility: visible !important;
    position: relative !important;
    z-index: 1 !important;
  }
}

.system-user-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mt15 {
  text-align: center;
  margin-top: 15px;
}

.title {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 15px;
}
</style>

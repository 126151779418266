<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('编辑安全组')" v-model="isShowDialog" width="70%" :modal-append-to-body="true"
               :append-to-body="true">
      <el-divider></el-divider>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane :label="$t('基本')" name="first">
          <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="90px" label-position="top"
                   ref="validateFrom" :rules="rules">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('名称')" prop="name">
                  <el-input v-model="ruleForm.name" :placeholder="$t('安全组名称')" maxlength="16" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item :label="$t('描述')" prop="remark">
                  <el-input v-model="ruleForm.remark" :rows="3" maxlength="64" type="textarea"/>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <span class="dialog-footer">
            <el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
            <el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
          </span>
        </el-tab-pane>
        <el-tab-pane :label="$t('规则')" name="second">
          <el-form :model="ruleForm" size="small" label-width="90px" label-position="left">
            <el-card shadow="never" class="mb10 u-el-button-small">
              <template #header>
                <div style="display: flex;">
                  <el-button type="primary" size="small" @click="onOpenAddP">
                    <el-icon :size="16" style="vertical-align: middle;margin-right:3px;">
                      <CirclePlus/>
                    </el-icon>
                    <span style="vertical-align: middle">{{ $t('添加') }}</span>
                  </el-button>
                  <el-button type="info" size="small" @click="delRulesSub">
                    <el-icon :size="16" style="vertical-align: middle;margin-right:3px;">
                      <Delete/>
                    </el-icon>
                    <span style="vertical-align: middle">{{ $t('删除') }}</span>
                  </el-button>
                  <el-input size="small" v-model="ruleForm.rules.keyword" :placeholder="$t('IP地址')"
                            style="width:160px;margin-left: auto;" @keydown.enter="ruleForm.rules">
                    <template #append>
                      <el-button @click="ruleData">
                        <el-icon :size="14">
                          <Search/>
                        </el-icon>
                      </el-button>
                    </template>
                  </el-input>
                </div>
              </template>
              <el-row :gutter="35">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <el-table :data="ruleForm.rules.data" stripe style="width: 100%" class="table" id="out-table"
                            @selection-change="selectionChange" @sort-change="changeRuleSort">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column :label="$t('类型')" width="90" show-overflow-tooltip>
                      <template #default="scope">
                        <el-tag size="medium" effect="plain" type="warning" v-if="scope.row.type == 0">
                          {{ $t('禁止') }}
                        </el-tag>
                        <el-tag size="medium" effect="plain" type="success" v-if="scope.row.type == 1">
                          {{ $t('允许') }}
                        </el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column prop="ip_value" :label="$t('IP地址')" width="140" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="port_range" :label="$t('端口范围')" width="120" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="priority" :label="$t('优先级')" width="100" show-overflow-tooltip
                                     sortable="custom"></el-table-column>
                    <el-table-column prop="create_time" :label="$t('创建时间')"
                                     show-overflow-tooltip sortable="custom" width="180"></el-table-column>
                    <el-table-column prop="path" :label="$t('操作')" width="90" fixed="right">
                      <template #default="scope">
                        <el-button type="text" @click="onOpenEditP(scope.row)" :title="$t('编辑')">
                          <el-icon :size="16">
                            <Edit/>
                          </el-icon>
                        </el-button>
                        <el-button type="text" @click="delRuleSub(scope.row)" :title="$t('删除')"
                                   style="margin-left: 0px !important;">
                          <el-icon :size="16" color="#f63d3d">
                            <Delete/>
                          </el-icon>
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-pagination v-if="clientWidth >= 1000" @size-change="onHandleSizeChange"
                                 @current-change="onHandleCurrentChange" class="mt15" :pager-count="5"
                                 :page-count="ruleForm.rules.pageCount"
                                 :current-page="ruleForm.rules.pageNum" background :page-size="ruleForm.rules.pageSize"
                                 layout="total, prev, pager, next, jumper"
                                 :total="ruleForm.rules.total">
                  </el-pagination>
                  <el-pagination v-else @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                                 :page-size="ruleForm.rules.pageSize" small layout=" prev, pager, next"
                                 :page-count="ruleForm.rules.pageCount"
                                 :current-page="ruleForm.rules.pageNum"
                                 :total="ruleForm.rules.total" class="mt15"></el-pagination>
                </el-col>
              </el-row>
            </el-card>
          </el-form>
          <addRuleP ref="addRuleRef" @print="refRule"/>
          <editRuleP ref="editRuleRef" @print="refRule"/>
        </el-tab-pane>
        <el-tab-pane :label="$t('应用于分机')" name="third">
          <el-form :model="ruleForm" size="small" label-width="90px" label-position="top">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <p class="member">{{ $t('分机') }}</p>
                <el-transfer v-model="rightValue" :props="{ key: 'id', label: 'dn',}" :data="leftValue" filterable
                             :titles="[$t('可用分机'), $t('应用分机')]" :button-texts="[$t('移除'), $t('添加')]"
                             :filter-placeholder="$t('搜索分机')" @change="changeMember">
                </el-transfer>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  onMounted, computed, ref,
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  safeInfo,
  safeUpdate,
  ruleList,
  ruleBatchDel,
  ruleDel, bindMember, unbindMember
} from "@/http/safe.api.js"
import {
  ElMessageBox,
  ElNotification
} from 'element-plus';
import addRuleP from "./addRule.vue";
import editRuleP from "./editRule.vue";
import {avaliableStation} from "@/http/extension.api";

export default {
  name: 'editSafeGroup',
  components: {addRuleP, editRuleP},
  emits: ["print"],
  setup(props, context) {
    const {t}      = useI18n()
    const state    = reactive({
      isShowDialog: false,
      id: '',
      activeName: 'first',
      tabPosition: 'left',
      selectionList: [],
      rightValue: [],
      leftValue: [],
      ruleForm: {
        name: '',
        remark: '',
        rules: {
          keyword: '',
          data: [],
          pageSize: 5,  //每页数据条数
          pageNum: 1,   //页码
          total: 0,     //总数据条数
          pageCount: 0, //总页数
          sortType: '',
          sortBy: ''
        },
      },
    });
    const dataInfo = (state) => {
      let params = {
        id: state.id
      }
      if (state.id) {
        safeInfo(params).then(res => {
          if (res.code == 200) {
            state.ruleForm.name   = res.data.name;
            state.ruleForm.remark = res.data.remark;
            state.rightValue      = res.data.member_ids;
          } else {
            ElNotification({
              title: t('失败'),
              message: res.msg,
              type: 'error',
              duration: 2000,
            });
          }
        }).catch(err => {
          console.log(err, 'err');
        })
      }
    }
    const ruleData = () => {
      let params = {
        id: state.id,
        keyword: state.ruleForm.rules.keyword,
        page: state.ruleForm.rules.pageNum,
        sort_type: state.ruleForm.rules.sortType,
        sort_by: state.ruleForm.rules.sortBy
      }
      ruleList(params).then(res => {
        if (res.code == 200) {
          state.ruleForm.rules.data      = res.data.list;
          state.ruleForm.rules.pageCount = res.data.pages;
          state.ruleForm.rules.pageSize  = res.data.length;
          state.ruleForm.rules.total     = res.data.rows;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      }).catch(err => {
        console.log(err, 'err');
      })
    }
    onMounted(() => {
    })
    //校验规则
    const rules = computed(() => {
      const rule = {
        name: [{
          required: true,
          message: t("请输入安全组名称"),
          trigger: "blur"
        }],
        remark: [{
          required: true,
          message: t("请输入备注信息"),
          trigger: "blur"
        }],
      };
      return rule;
    })

    const addRuleRef  = ref();
    const editRuleRef = ref();
    const onOpenAddP  = () => {
      addRuleRef.value.openDialog({id: state.id});
    };
    const onOpenEditP = (row) => {
      editRuleRef.value.openDialog({id: state.id, rule_id: row.id});
    };
    // 打开弹窗
    const openDialog  = (row) => {
      state.activeName   = 'first';
      state.id           = row.id;
      state.isShowDialog = true;
      dataInfo(state);
      ruleData();

      let params = {
        keyword: 'safe',
        id: state.id
      }
      avaliableStation(params).then(res => {
        if (res.code == 200) {
          state.leftValue = res.data;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    };
    // 关闭弹窗
    const closeDialog = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel    = () => {
      closeDialog();
      initForm();
    };
    // 新增
    const onSubmit    = () => {
      const params = {
        id: state.id,
        name: state.ruleForm.name,
        remark: state.ruleForm.remark
      };
      safeUpdate(params).then((res) => {
        if (res.code == 200) {
          ElNotification({
            title: t("成功"),
            message: t("编辑成功"),
            type: 'success',
            duration: 2000,
          });
          state.isShowDialog = false;
          context.emit("print");
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    };

    // 右侧列表元素变化时触发
    // value右边框里面所有的key值  direction是方向(left和right)，movedKeys是移动的key值
    const changeMember = (value, direction, movedKeys) => {
      // 判断移动方向
      if (direction === "right") {
        state.editLayoutData = movedKeys;
        let params           = {
          id: state.id,
          members: state.editLayoutData
        }
        bindMember(params).then(res => {
          if (res.code == 200) {
            ElNotification({
              title: t("成功"),
              message: t("添加成功"),
              type: "success",
              duration: 2000,
            });
          } else {
            ElNotification({
              title: t('失败'),
              message: res.msg,
              type: 'error',
              duration: 2000,
            });
          }
        })
      } else {
        state.editLayoutData = movedKeys;
        let params           = {
          id: state.id,
          members: state.editLayoutData
        }
        unbindMember(params).then(res => {
          if (res.code == 200) {
            ElNotification({
              title: t("成功"),
              message: t("解绑成功"),
              type: "success",
              duration: 2000,
            });
          } else {
            ElNotification({
              title: t('失败'),
              message: res.msg,
              type: 'error',
              duration: 2000,
            });
          }
        })
      }
    }
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm     = () => {
      state.ruleForm.name   = '';
      state.ruleForm.remark = '';
    };


    const selectionChange = (list) => {
      state.selectionList = list.map(item => {
        return item.id
      });
    }
    const changeRuleSort  = (column) => {
      state.ruleForm.rules.sortBy   = column.prop;
      state.ruleForm.rules.sortType = column.order;
      ruleData()
    }

    // 分页改变
    const onHandleCurrentChange = (val) => {
      state.ruleForm.rules.pageNum = val;
      ruleData();
    };

    // 分页改变
    const onHandleSizeChange = (val) => {
      state.ruleForm.rules.pageSize = val;
      ruleData();
    };

    const delRuleSub = (row) => {
      ElMessageBox.confirm(t("你确定要删除该数据吗"), t("提示"), {
        confirmButtonText: t("确定"),
        cancelButtonText: t("取消"),
        type: "warning",
        callback: (action) => {
          if (action === "confirm") {
            let params = {
              id: row.id
            }
            ruleDel(params).then(res => {
              if (res.code == 200) {
                ElNotification({
                  title: t("成功"),
                  message: t("删除成功"),
                  type: "success",
                  duration: 2000,
                });
                ruleData()
              } else {
                ElNotification({
                  title: t('失败'),
                  message: res.msg,
                  type: 'error',
                  duration: 2000,
                });
              }
            })
          }
        }
      })
    }

    //批量删除
    const delRulesSub = () => {
      if (state.selectionList.length === 0) {
        ElNotification({
          title: t("提示"),
          message: t("请选择要删除的数据"),
          type: "warning",
          duration: 2000,
        });
      } else {
        ElMessageBox.confirm(t("你确定要删除该数据吗"), t("提示"), {
          confirmButtonText: t("确定"),
          cancelButtonText: t("取消"),
          type: "warning",
          callback: (action) => {
            if (action === "confirm") {
              let params = {
                ids: state.selectionList
              }
              ruleBatchDel(params).then(res => {
                if (res.code == 200) {
                  ElNotification({
                    title: t("成功"),
                    message: t("删除成功"),
                    type: "success",
                    duration: 2000,
                  });
                  ruleData()
                } else {
                  ElNotification({
                    title: t('失败'),
                    message: res.msg,
                    type: 'error',
                    duration: 2000,
                  });
                }
              })
            }
          }
        })
      }
    }

    const refRule = () => {
      ruleData();
    }

    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      rules,
      ...toRefs(state),
      addRuleRef,
      editRuleRef,
      onOpenAddP,
      onOpenEditP,
      ruleData,
      selectionChange,
      changeRuleSort,
      onHandleCurrentChange,
      onHandleSizeChange,
      delRuleSub,
      delRulesSub,
      refRule,
      changeMember,
    };
  },
};
</script>
<style lang="scss" scoped>
.el-dialog__body {
  padding-top: 0;
}

.dialog-footer {
  float: right;
}
</style>

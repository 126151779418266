<template>
  <el-card class="!border-none mb10 status-tag" v-show="sysTitle" shadow="never">
    <div style="display: flex">
      <el-alert
          type="error"
          :title="sysTitle"
          :closable="false"
          show-icon
          style="flex-direction: row;font-weight: 500"
      />
    </div>
  </el-card>
  <div>
    <el-card shadow="hover">
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="$t('个人设置')" name="first" :key="'first'">
          <personalSetting v-if="isChildUpdate1" @changeTag="changeTag"/>
        </el-tab-pane>
        <el-tab-pane :label="$t('黑名单')" name="second" :key="'second'">
          <ipBlacklist v-if="isChildUpdate2" @changeTag="changeTag"/>
        </el-tab-pane>
        <el-tab-pane :label="$t('安全组')" name="third" :key="'third'">
          <safeGroup v-if="isChildUpdate3" @changeTag="changeTag"/>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
import personalSetting from './components/personalSetting';
import ipBlacklist from './ipBlacklist';
import safeGroup from './safeGroup.vue';
import {reactive, toRefs} from "vue";

export default {
  name: 'systemSetup',
  components: {
    personalSetting,
    ipBlacklist,
    safeGroup,
  },
  setup() {
    const state       = reactive({
      sysTitle: "",
      activeName: "first",
      isChildUpdate1: true,
      isChildUpdate2: false,
      isChildUpdate3: false,
    });
    const changeTag   = (msg) => {
      if (msg) {
        state.sysTitle = msg;
      } else {
        state.sysTitle = '';
      }
    }
    const handleClick = (tab) => {
      if (tab.props.name == "first") {
        state.isChildUpdate1 = true;
        state.isChildUpdate2 = false;
        state.isChildUpdate3 = false;
      } else if (tab.props.name == "second") {
        state.isChildUpdate1 = false;
        state.isChildUpdate2 = true;
        state.isChildUpdate3 = true;
      } else if (tab.props.name == "third") {
        state.isChildUpdate1 = false;
        state.isChildUpdate2 = false;
        state.isChildUpdate3 = true;
      }
    }
    return {
      ...toRefs(state),
      handleClick,
      changeTag,
    }
  }
}
</script>
<style lang="scss" scoped>
.el-card {
  --el-card-padding: 0;
}
</style>

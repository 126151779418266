import request from './index.js'

export function safeList(data = {}) {
    return request({
        url: "/api/safe/group/search",
        method: "GET",
        params: data
    })
}

export function safeInfo(data = {}) {
    return request({
        url: "/api/safe/group/find",
        method: "GET",
        params: data
    })
}

export function safeAdd(data = {}) {
    return request({
        url: "/api/safe/group/add",
        method: "POST",
        params: data
    })
}

export function safeUpdate(data = {}) {
    return request({
        url: "/api/safe/group/update",
        method: "PUT",
        params: data
    })
}

export function safeDel(data = {}) {
    return request({
        url: "/api/safe/group/delete",
        method: "DELETE",
        params: data
    })
}

export function safeBatchDel(data = {}) {
    return request({
        url: "/api/safe/group/batchdel",
        method: "DELETE",
        params: data
    })
}

export function ruleList(data = {}) {
    return request({
        url: "/api/safe/rule/search",
        method: "GET",
        params: data
    })
}

export function ruleInfo(data = {}) {
    return request({
        url: "/api/safe/rule/find",
        method: "GET",
        params: data
    })
}

export function ruleAdd(data = {}) {
    return request({
        url: "/api/safe/rule/add",
        method: "POST",
        params: data
    })
}

export function ruleUpdate(data = {}) {
    return request({
        url: "/api/safe/rule/update",
        method: "PUT",
        params: data
    })
}

export function ruleDel(data = {}) {
    return request({
        url: "/api/safe/rule/delete",
        method: "DELETE",
        params: data
    })
}

export function ruleBatchDel(data = {}) {
    return request({
        url: "/api/safe/rule/batchdel",
        method: "DELETE",
        params: data
    })
}

export function bindMember(data={}) {
    return request({
        url: "/api/safe/member",
        method: "POST",
        data
    })
}

export function unbindMember(data={}) {
    return request({
        url: "/api/safe/member",
        method: "DELETE",
        data
    })
}

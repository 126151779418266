import request from './index.js'

//获取黑名单列表
export function getBlackList(data={}) {
  return request({
    url: "/api/blacklist/search",
    method: "GET",
    params: data
  })
}
//黑名单添加
export function addBlackItem(data={}) {
  return request({
    url: "/api/blacklist/add",
    method: "POST",
    data //注意这块传参 其他没啥
  })
}
//黑名单删除
export function deleteBlackItem(data={}) {
  return request({
    url: "/api/blacklist/delete",
    method: "DELETE",
    data
  })
}
//黑名单批量删除
export function deleteBlackItems(data={}) {
  return request({
    url: "/api/blacklist/batchdel",
    method: "DELETE",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}
//获取单个黑名单列表
export function findBlackItem(data={}) {
  return request({
    url: "/api/blacklist/find",
    method: "GET",
    params: data
  })
}
//黑名单编辑
export function updateBlackList(data={}) {
  return request({
    url: "/api/blacklist/update",
    method: "PUT",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}
export function postRequestDemo(data={}) {
  return request({
    url: "/api/blacklist/search",
    method: "POST",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}
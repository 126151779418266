import request from './index.js'

//获取分机列表
export function getStation(data={}) {
  return request({
    url: "/api/station/search",
    method: "GET",
    params: data
  })
}
//分机可用列表
export function avaliableStation(data={}) {
  return request({
    url: "/api/station/avaliable",
    method: "GET",
    params: data
  })
}
//获取单个分机列表
export function findStation(data={}) {
  return request({
    url: "/api/station/find",
    method: "GET",
    params: data
  })
}
//分机添加
export function addStation(data={}) {
  return request({
    url: "/api/station/add",
    method: "POST",
    data
  })
}
//分机批量添加
export function batchaddStation(data={}) {
  return request({
    url: "/api/station/batchadd",
    method: "POST",
    data
  })
}

//分机导入
export function impStation(data = {}) {
  return request({
    url: "/api/station/import",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data;charset=UTF-8"
    },
    data
  })
}

//分机编辑
export function updateStation(data={}) {
  return request({
    url: "/api/station/update",
    method: "PUT",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}
//分机删除
export function deleteStation(data={}) {
  return request({
    url: "/api/station/delete",
    method: "DELETE",
    data
  })
}
//分机批量删除
export function deleteStations(data={}) {
  return request({
    url: "/api/station/batchdel",
    method: "DELETE",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}

//分机设置转接编辑
export function updateTransfer(data={}) {
  return request({
    url: "/api/station/transfer",
    method: "PUT",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}

//分机外线号码编辑
export function updateAni(data={}) {
  return request({
    url: "/api/station/ani",
    method: "PUT",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}

//分机外线号码编辑
export function updateVoice(data={}) {
  return request({
    url: "/api/station/voice",
    method: "PUT",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}

<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('编辑黑名单')" v-model="isShowDialog" width="60%">
      <el-divider></el-divider>
      <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="90px" label-position="top"
               ref="validateFrom" :rules="rules">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('类型')" prop="type">
              <el-select v-model="ruleForm.type" :placeholder="$t('请选择类型')">
                <el-option :label="$t('IP地址')" :value="0"></el-option>
                <el-option :label="$t('号码')" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('信息')" prop="data">
              <el-input v-model="ruleForm.data" :placeholder="$t('IP地址或号码')" maxlength="32" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item :label="$t('描述')" prop="comment">
              <el-input v-model="ruleForm.comment" :rows="3" maxlength="64" type="textarea"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  onMounted, computed,
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  findBlackItem,
  updateBlackList,
} from "@/http/safety.api.js"
import {
  ElNotification
} from 'element-plus';

export default {
  name: 'incomingCalleditMenu',
  emits: ["print"],
  setup(props, context) {
    const {t}               = useI18n()
    const state             = reactive({
      isShowDialog: false,
      id: '',
      ruleForm: {
        type: 0,
        data: '',
        comment: '',
      },
    });
    const blacklistEditData = (state) => {
      let params = {
        id: state.id
      }
      if (state.id) {
        findBlackItem(params).then(res => {
          if (res.code == 200) {
            state.ruleForm = res.data;
          } else {
            ElNotification({
              title: t('失败'),
              message: res.msg,
              type: 'error',
              duration: 2000,
            });
          }
        }).catch(err => {
          console.log(err, 'err');
        })
      }
    }
    onMounted(() => {
    })
    //校验规则
    const rules       = computed(() => {
      const rule = {
        type: [{
          required: true,
          message: t("请选择类型"),
          trigger: "change"
        }],
        data: [{
          required: true,
          message: t("请填写IP地址或号码信息"),
          trigger: "blur"
        }],
      };
      return rule;
    })
    // 打开弹窗
    const openDialog  = (row) => {
      state.id           = row.id;
      state.isShowDialog = true;
      blacklistEditData(state);
    };
    // 关闭弹窗
    const closeDialog = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel    = () => {
      closeDialog();
      initForm();
    };
    // 新增
    const onSubmit    = () => {
      const params = {
        id: state.id,
        type: state.ruleForm.type,
        data: state.ruleForm.data,
        comment: state.ruleForm.comment
      };
      updateBlackList(params).then((res) => {
        if (res.code == 200) {
          ElNotification({
            title: t("成功"),
            message: t("编辑成功"),
            type: 'success',
            duration: 2000,
          });
          state.isShowDialog = false;
          context.emit("print");
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    };
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm    = () => {
      state.ruleForm.type    = 0;
      state.ruleForm.data    = '';
      state.ruleForm.comment = '';
    };

    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      rules,
      ...toRefs(state),
      ...blacklistEditData(state)
    };
  },
};
</script>
<style lang="scss" scoped>
.el-dialog__body {
  padding-top: 0;
}

.column {
  flex-direction: column;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
  margin-bottom: 35px;
}

.footer {
  audio {
    margin-top: 5px;
    width: 100%;
  }
}

.el-form-item__content {
  margin-left: 0 !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.item {
  width: 100px;

  /* 调整elementUI中样式 如果不需要调整请忽略 */
  .el-input__inner {
    height: 24px !important;
  }
}

.txt {
  line-height: 24px;
  padding: 0 9px;
  box-sizing: border-box;
}
</style>

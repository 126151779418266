<template>
  <div>
    <el-form :model="ruleForm" label-width="90px" class="demo-ruleForm" size="small"
             label-position="top" ref="validateFrom" :rules="rules">
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('用户名')" prop="name">
            <el-input v-model="ruleForm.name" :placeholder="$t('请输入用户名')" clearable disabled/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('旧密码')" prop="password">
            <el-input v-model="ruleForm.password" :placeholder="$t('请输入密码')" maxlength="16" show-password/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item label="" prop="new_password">
            <el-tooltip class="box-item" effect="dark" placement="bottom">
              <span>{{ $t('新密码') }}
                <el-icon size="small" color="#409eff">
                  <info-filled/>
                </el-icon>
              </span>
              <template #content>
                {{ $t('八到12位包含大小写字母和数字') }}
              </template>
            </el-tooltip>
            <el-input v-model="ruleForm.new_password" :placeholder="$t('请输入新密码')" maxlength="16" show-password/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('确认密码')" prop="confirm_password">
            <el-input v-model="ruleForm.confirm_password" :placeholder="$t('请确认密码')" maxlength="16" show-password/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-affix position="bottom" :offset="10" style="margin-top:10px;">
        <el-button size="small" type="primary" @click="submitForm">{{ $t('保存') }}</el-button>
      </el-affix>
    </el-form>
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  ref, computed, onMounted
} from 'vue'
import {useI18n} from "vue-i18n";
import {
  infoAuth,
  updateAuth
} from '@/http/api.js';
import {
  ElNotification
} from 'element-plus';

function blackList(state) {
  infoAuth().then(res => {
    if (res.code == 200) {
      state.ruleForm.name = res.data.phone
    } else {
      ElNotification({
        title: '失败',
        message: res.msg,
        type: 'error',
        duration: 2000,
      });
    }
  }).catch(err => {
    console.log(err, 'err');
  })
}

export default {
  name: 'personalSetting',
  setup() {
    const {t}          = useI18n()
    const state        = reactive({
      ruleForm: {
        name: '',
        password: '',
        new_password: '',
        confirm_password: '',
      },
      listMenu: {
        title: t('一键录音'),
        checked1: false
      },
    });
    const validateFrom = ref()
    onMounted(() => {
      blackList(state)
    })
    //校验规则
    const rules = computed(() => {
      const rule = {
        password: [
          {
            required: true,
            message: t("请输入旧密码"),
            trigger: "blur"
          }],
        new_password: [
          {
            required: true,
            message: t("请输入新密码"),
            trigger: "blur"
          },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,12}$/,
            message: t('八到12位包含大小写字母和数字'),
            trigger: 'blur',
          }
        ],
        confirm_password: [
          {
            required: true,
            message: t("请输入新密码"),
            trigger: "blur"
          }
        ]
      }
      return rule;
    });

    function submitForm() {
      validateFrom.value.validate((valid) => {
        if (valid) {
          const params = {
            name: state.ruleForm.name,
            password: state.ruleForm.password,
            new_password: state.ruleForm.new_password,
            confirm_password: state.ruleForm.confirm_password,
          };
          updateAuth(params).then(res => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("编辑成功"),
                type: 'success',
                duration: 2000,
              });
              // validateFrom.value.resetFields();
              // state.isShowDialog= false;
              // context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      });
    }

    return {
      ...toRefs(state),
      submitForm,
      validateFrom,
      rules
    }
  }
}
</script>
<style scoped>
.el-affix :deep(.el-affix--fixed) {
  display: flex;
  justify-content: center;
  background: white;
}

.el-button--small {
  margin: 0 10px;
}
</style>

<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('新增安全组规则')" v-model="isShowDialog" @close="initForm" width="60%">
      <el-divider></el-divider>
      <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="90px" label-position="top"
               ref="validateFrom" :rules="rules">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('类型')" prop="type">
              <el-select v-model="ruleForm.type" :placeholder="$t('请选择类型')">
                <el-option :label="$t('禁止')" :value="0"></el-option>
                <el-option :label="$t('允许')" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('IP地址')" prop="ip_value">
              <el-input v-model="ruleForm.ip_value" :placeholder="$t('IP地址')" maxlength="32" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="">
              <el-tooltip class="box-item" effect="dark" placement="bottom">
                <span>{{ $t('端口范围') }}
                  <el-icon size="small" color="#409eff">
                    <info-filled/>
                  </el-icon>
                </span>
                <template #content>
                  1 - 65535
                </template>
              </el-tooltip>
              <div class="input-number-range">
                <div class="flex">
                  <div class="from">
                    <el-input
                        ref="input_from"
                        v-model="ruleForm.port_start"
                        :placeholder="$t('起始值')"
                        @blur="handleBlurFrom"
                        @focus="handleFocusFrom"
                        @input="handleInputFrom"
                        @change="handleInputChangeFrom"
                    ></el-input>
                  </div>
                  <div class="center">
                    <span>-</span>
                  </div>
                  <div class="to">
                    <el-input
                        ref="input_to"
                        v-model="ruleForm.port_end"
                        :placeholder="$t('结束值')"
                        @blur="handleBlurTo"
                        @focus="handleFocusTo"
                        @input="handleInputTo"
                        @change="handleInputChangeTo"
                    ></el-input>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('优先级')" prop="priority">
              <el-input-number v-model="ruleForm.priority" :placeholder="$t('请输入优先级')" clearable :min="0"
                               :max="999" maxlength="3"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item :label="$t('描述')" prop="remark">
              <el-input v-model="ruleForm.remark" :rows="3" maxlength="64" type="textarea"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed,
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  ElNotification
} from 'element-plus';
import {ruleAdd} from "@/http/safe.api.js"

export default {
  name: 'addSafeGroup',
  components: {},
  emits: ["print"],
  setup(props, context) {
    const {t}          = useI18n()
    const state        = reactive({
      isShowDialog: false,
      id: '',
      ruleForm: {
        type: 0,
        ip_value: '',
        port_start: '',
        port_end: '',
        priority: '',
        remark: '',
      },
    });
    const validateFrom = ref()

    // 新增
    const onSubmit = () => {
      validateFrom.value.validate((valid) => {
        if (valid) {
          const params = {
            id: state.id,
            type: state.ruleForm.type,
            ip_value: state.ruleForm.ip_value,
            port_start: state.ruleForm.port_start,
            port_end: state.ruleForm.port_end,
            priority: state.ruleForm.priority,
            remark: state.ruleForm.remark
          };
          ruleAdd(params).then((res) => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("添加成功"),
                type: 'success',
                duration: 2000,
              });
              validateFrom.value.resetFields();
              state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          }).catch(err => {
            console.log(err, 'err')
          })
        }
      });
    };

    //校验规则
    const rules       = computed(() => {
      const rule = {
        type: [{
          required: true,
          message: t("请选择类型"),
          trigger: "blur"
        }],
        ip_value: [{
          required: true,
          message: t("请输入IP地址"),
          trigger: "blur"
        }],
        priority: [{
          required: true,
          message: t("请定义优先级"),
          trigger: "blur"
        }]
      };
      return rule;
    })
    // 打开弹窗
    const openDialog  = (row) => {
      state.id           = row.id;
      state.isShowDialog = true;
    };
    // 关闭弹窗
    const closeDialog = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel    = () => {
      closeDialog();
      initForm();
    };
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm    = () => {
      state.ruleForm = {
        type: 0,
        ip_value: '',
        port_start: '',
        port_end: '',
        priority: '',
        remark: '',
      };
    };

    function handleBlurFrom(event) {
      context.emit('blurfrom', event)
    }

    function handleFocusFrom(event) {
      context.emit('focusfrom', event)
    }

    function handleBlurTo(event) {
      context.emit('blurto', event)
    }

    function handleFocusTo(event) {
      context.emit('focusto', event)
    }

    function handleInputFrom(value) {
      context.emit('inputfrom', value)
    }

    function handleInputTo(value) {
      context.emit('inputto', value)
    }

    // from输入框change事件
    function handleInputChangeFrom(value) {
      // 如果是非数字空返回null
      if (isNaN(value) || value === '') {
        context.emit('input', [null, state.ruleForm.port_end])
        context.emit('changefrom', state.ruleForm.port_start)
        return
      }

      // 初始化数字精度
      state.ruleForm.rtpSport = parseInt(value);

      // 如果from > to 将from值替换成to
      if (typeof state.ruleForm.port_end === 'number') {
        state.ruleForm.rtpSport =
            parseFloat(state.ruleForm.port_start) <= parseFloat(state.ruleForm.port_end)
                ? state.ruleForm.port_start
                : state.ruleForm.port_end
      }
      context.emit('input', [state.ruleForm.port_start, state.ruleForm.port_end])
      context.emit('changefrom', state.ruleForm.port_start)
    }

    // to输入框change事件
    function handleInputChangeTo(value) {
      // 如果是非数字空返回null
      if (isNaN(value) || value === '') {
        context.emit('input', [state.ruleForm.port_start, null])
        context.emit('changefrom', state.ruleForm.port_end)
        return
      }

      // 初始化数字精度
      state.ruleForm.rtpEport = parseInt(value);

      // 如果to < tfrom 将to值替换成from
      if (typeof state.ruleForm.port_start === 'number') {
        state.ruleForm.rtpEport =
            parseFloat(state.ruleForm.port_end) >= parseFloat(state.ruleForm.port_start)
                ? state.ruleForm.port_end
                : state.ruleForm.port_start
      }
      context.emit('input', [state.ruleForm.port_start, state.ruleForm.port_end])
      context.emit('changeto', state.ruleForm.port_end)
    }

    return {
      openDialog,
      closeDialog,
      onCancel,
      initForm,
      ...toRefs(state),
      rules,
      validateFrom,
      onSubmit,
      handleBlurFrom,
      handleBlurTo,
      handleFocusFrom,
      handleFocusTo,
      handleInputFrom,
      handleInputTo,
      handleInputChangeFrom,
      handleInputChangeTo,
    };
  },
};
</script>
<style lang="scss">
.el-dialog__body {
  padding-top: 0;
}

.column {
  flex-direction: column;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
  margin-bottom: 35px;
}

.footer {
  audio {
    margin-top: 5px;
    width: 100%;
  }
}

.el-form-item__content {
  margin-left: 0 !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.item {
  width: 100px;

  /* 调整elementUI中样式 如果不需要调整请忽略 */
  .el-input__inner {
    height: 24px !important;
  }
}

.txt {
  line-height: 24px;
  padding: 0 9px;
  box-sizing: border-box;
}
</style>

<style lang="scss" scoped>
// 取消element原有的input框样式
:deep(.el-input--mini .el-input__inner) {
  border: 0px;
  margin: 0;
  padding: 0 15px;
  background-color: transparent;
}

.input-number-range {
  background-color: #fff;
  //border: 1px solid #dcdfe6;
  border-radius: 4px;
}

.flex {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .center {
    margin-top: 1px;
  }
}

.is-disabled {
  background-color: #eef0f6;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
}
</style>
